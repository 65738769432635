import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder/ImageWithPlaceholder';
import { EOpenVideoOnYoutube, TVideo } from '../MultiPlayer.contstants';

type TVideoListProps = {
  videos: TVideo[];
  onVideoClick: (video: TVideo) => void;
  openVideoOnYoutube?: EOpenVideoOnYoutube;
};
export const VideoList: React.FC<TVideoListProps> = ({
  videos,
  onVideoClick,
  openVideoOnYoutube,
}) => {
  const videoItemClass = 'flex gap-4 xl:max-2xl:gap-3 items-start';
  return (
    <div className='flex flex-col gap-2 xl:gap-3 2xl:gap-4'>
      {videos.map(video => {
        const youtubeUrl = `https://www.youtube.com/watch?v=${video.videoID}`;
        const videoItem = (
          <>
            {video.cover ? (
              <div className='shrink-0 grow-0 basis-[9.75rem] overflow-hidden rounded-sm xl:max-2xl:basis-[7.75rem]'>
                <ImageWithPlaceholder
                  {...video.cover}
                  className='aspect-video object-cover'
                />
              </div>
            ) : null}
            <p className='text-interface-100 line-clamp-2 text-lg font-normal leading-relaxed'>
              {video.title}
            </p>
          </>
        );
        return (
          <React.Fragment key={video.id}>
            {openVideoOnYoutube === EOpenVideoOnYoutube.Yes ? (
              <a
                href={youtubeUrl}
                className={videoItemClass}
                target='_blank'
                rel='noopener noreferrer'
              >
                {videoItem}
              </a>
            ) : (
              <div
                onClick={() => {
                  onVideoClick(video);
                }}
                className={videoItemClass}
              >
                {videoItem}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
