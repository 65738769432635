import { LinkProps } from 'next/link';
import React, { useState } from 'react';

import { TabContent } from './Components/TabContent';
import { Tabs } from './Components/Tabs';
import { EOpenVideoOnYoutube, TVideo } from './MultiPlayer.contstants';
import { Container, EContainerVariant } from '../../sections/Container';

type TMultiPlayerProps = {
  tabs: {
    tabTitle: string;
    url?: LinkProps;
    videos?: TVideo[];
  }[];
  openVideoOnYoutube?: EOpenVideoOnYoutube;
  bottomLink?: LinkProps;
};

const MultiPlayer: React.FC<TMultiPlayerProps> = ({
  tabs,
  openVideoOnYoutube = EOpenVideoOnYoutube.Yes,
  bottomLink,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  return (
    <div className='flex flex-col gap-2 overflow-hidden xl:gap-4'>
      <Container variant={EContainerVariant.Main}>
        <Tabs
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          onClick={setActiveTabIndex}
        />
      </Container>
      <Container variant={EContainerVariant.Main}>
        <TabContent
          videos={tabs[activeTabIndex].videos}
          openVideoOnYoutube={openVideoOnYoutube}
          bottomLink={bottomLink}
        />
      </Container>
    </div>
  );
};

export default MultiPlayer;
