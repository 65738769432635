import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder/ImageWithPlaceholder';
import { Video } from '../../../atoms/Video';
import { EOpenVideoOnYoutube, TVideo } from '../MultiPlayer.contstants';

type TSingleVideoProps = {
  video: TVideo;
  openVideoOnYoutube?: EOpenVideoOnYoutube;
};
export const SingleVideo: React.FC<TSingleVideoProps> = ({
  video,
  openVideoOnYoutube,
}) => {
  const youtubeUrl = `https://www.youtube.com/watch?v=${video.videoID}`;

  return (
    <div className='w-full'>
      {openVideoOnYoutube === EOpenVideoOnYoutube.Yes ? (
        <a href={youtubeUrl} target='_blank' rel='noopener noreferrer'>
          {video.cover ? (
            <ImageWithPlaceholder
              {...video.cover}
              className='aspect-video h-auto w-full rounded object-cover md:rounded-md lg:rounded-lg'
            />
          ) : null}
        </a>
      ) : (
        <Video
          video={{ ...video, cover: '' }}
          iframeClassName='rounded sm:rounded object-cover md:rounded-md lg:rounded-lg'
          hasCover={false}
          showBackdrop={false}
          showPlayerButton={false}
        />
      )}
    </div>
  );
};
