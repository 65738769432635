import Link, { LinkProps } from 'next/link';
import React from 'react';
import { Mousewheel, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  ESolidTabSize,
  ESolidTabTheme,
  SolidTab,
} from '../../../atoms/SolidTab';

type TTabsProps = {
  tabs: { tabTitle: string; url?: LinkProps }[];
  activeTabIndex: number;
  onClick: (index: number) => void;
};

export const Tabs: React.FC<TTabsProps> = ({
  tabs,
  activeTabIndex,
  onClick,
}) => {
  return (
    <Swiper
      className='relative mx-[initial] flex overflow-visible'
      spaceBetween={8}
      slidesPerView={'auto'}
      wrapperClass={'flex'}
      loop={false}
      pagination={false}
      simulateTouch={true}
      followFinger={true}
      freeMode={true}
      grabCursor={true}
      modules={[Mousewheel, FreeMode]}
      mousewheel={{ releaseOnEdges: true, forceToAxis: true }}
    >
      {tabs.map(({ tabTitle, url }, i) => {
        const solidTab = (
          <SolidTab
            onClick={() => !url && onClick(i)}
            label={tabTitle}
            value={tabTitle}
            size={ESolidTabSize.Outline}
            theme={ESolidTabTheme.Ordinary}
            isActive={i === activeTabIndex}
          />
        );
        return (
          <SwiperSlide className='w-auto cursor-grab select-none pb-4' key={i}>
            {url ? (
              <Link
                draggable='false'
                className='select-none [-webkit-user-drag:none]'
                {...url}
              >
                {solidTab}
              </Link>
            ) : (
              solidTab
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
