import { User } from '@shared/master-types';
import { ImageProps } from 'next/image';

export type TVideo = {
  id: string;
  title: string;
  videoID: string;
  cover: ImageProps | null;
  author?: string | User;
  name: string;
  time: string;
  releaseDate?: string;
  updatedAt: string;
  createdAt: string;
};

export const enum EOpenVideoOnYoutube {
  Yes = 'yes',
  No = 'no',
}
