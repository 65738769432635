import { LinkProps } from 'next/link';
import React, { useEffect, useState } from 'react';

import { SingleVideo } from './SingleVideo';
import { VideoList } from './VideoList';
import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../../../atoms/Button';
import { EOpenVideoOnYoutube, TVideo } from '../MultiPlayer.contstants';

export type TTabContent = {
  videos?: TVideo[];
  openVideoOnYoutube?: EOpenVideoOnYoutube;
  bottomLink?: LinkProps;
};

export const TabContent: React.FC<TTabContent> = ({
  videos = [],
  openVideoOnYoutube,
  bottomLink,
}) => {
  const [selectedVideo, setSelectedVideo] = useState<TVideo>(videos[0]); // Initialize with the first video
  const [videoList, setVideoList] = useState<TVideo[]>(videos.slice(1)); // The rest are in the sidebar
  useEffect(() => {
    if (videos.length) {
      setSelectedVideo(videos[0]);
      setVideoList(videos.slice(1));
    }
  }, [videos]);
  const handleVideoClick = (clickedVideo: TVideo): void => {
    const clickedVideoIndex = videoList.findIndex(
      video => video.id === clickedVideo.id,
    );
    if (clickedVideoIndex === -1) return;
    const updatedVideoList = [
      ...videoList.slice(0, clickedVideoIndex),
      selectedVideo,
      ...videoList.slice(clickedVideoIndex + 1),
    ];
    setSelectedVideo(clickedVideo);
    setVideoList(updatedVideoList);
  };
  return (
    <div className='flex flex-col gap-4 xl:flex-row xl:items-stretch'>
      <div className='grow-0 xl:shrink-0 xl:basis-[41.125rem] 2xl:basis-[51rem]'>
        <SingleVideo
          video={selectedVideo}
          openVideoOnYoutube={openVideoOnYoutube}
        />
      </div>
      <div className='flex shrink grow basis-0 flex-col gap-4'>
        <VideoList
          videos={videoList}
          onVideoClick={handleVideoClick}
          openVideoOnYoutube={openVideoOnYoutube}
        />
        {bottomLink ? (
          <div
            className={
              'mt-auto border-b border-surface-200 pb-5 opacity-60 2xl:pb-4'
            }
          >
            <Button
              {...bottomLink}
              variant={EButtonVariant.Text}
              size={EButtonSize.Large}
              theme={EButtonTheme.Secondary}
              className='justify-start'
            ></Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
